import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./careFeatures.css";
import { Element } from "react-scroll";

const leftVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const rightVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const CareFeatures = ({ careFeature }) => {
  const [image, setImage] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFullScreenImage = (itemImage) => {
    setImage(itemImage);
    setIsFullScreen(!isFullScreen);
  };

  const renderFeatureSection = (item, isEven) => (
    <section
      className="feature-section"
      style={{ backgroundColor: isEven ? "whitesmoke" : "white" }}
    >
      <div id={item.id}>
        <div className="container feature-container">
          <div className="row">
            {isEven ? (
              <>
                <Element
                  className="col-lg-7 col-md-7 col-12 feature-title"
                  // id={item.id}
                  // name={item.id}
                >
                  <h3 style={{ fontWeight: 200 }}>{item?.title}</h3>
                  <motion.p
                    className="feature-desc"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    variants={leftVariants}
                  >
                    {item?.desc}
                  </motion.p>
                  <p className="feature-box">
                    <motion.ul
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.8 }}
                      variants={leftVariants}
                    >
                      {/* {item?.listItem} */}
                      {item.listItem &&
                        item.listItem.map((listItem, index) => (
                          <li key={index}>{listItem}</li>
                        ))}
                    </motion.ul>
                  </p>
                </Element>
                <motion.figure
                  className="col-lg-5 col-md-5 col-12 feature-figure"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                  variants={rightVariants}
                >
                  <div className="feature-item-container">
                    <div className="fe">
                      <img
                        onClick={() => handleFullScreenImage(item?.image)}
                        src={item?.image}
                        alt={item?.alt}
                        width="100%"
                        className={`${
                          careFeature.length === 5
                            ? "special-feature-image"
                            : "feature-image"
                        }  "new-class-name" `}
                      />
                    </div>
                  </div>
                </motion.figure>
              </>
            ) : (
              <>
                <motion.figure
                  className="col-lg-5 col-md-5 col-12 feature-figure"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                  variants={leftVariants}
                >
                  <div className="feature-item-container">
                    <div className="fe">
                      <img
                        onClick={() => handleFullScreenImage(item?.image)}
                        src={item?.image}
                        alt={item?.alt}
                        style={{ width: "80%" }}
                        className={
                          careFeature.length === 5
                            ? "special-feature-image"
                            : "feature-image"
                        }
                      />
                    </div>
                  </div>
                </motion.figure>
                <div className="col-lg-7 col-md-7 col-12 feature-title">
                  <h3 style={{ fontWeight: 200 }}>{item?.title}</h3>
                  <motion.p
                    className="feature-desc"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    variants={rightVariants}
                  >
                    {item?.desc}
                  </motion.p>
                  <div className="feature-box">
                    <motion.ul
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.8 }}
                      variants={rightVariants}
                    >
                      {/* {item?.listItem} */}
                      {item.listItem &&
                        item.listItem.map((listItem, index) => (
                          <li key={index}>{listItem}</li>
                        ))}
                    </motion.ul>
                  </div>
                </div>
              </>
            )}
            {/* Full screen image section */}
            {isFullScreen && (
              <div
                className="full-feature-image"
                onClick={() => setIsFullScreen(!isFullScreen)}
              >
                <img src={image} alt="Full" className="full-screen-image" />
              </div>
            )}
          </div>
          {item?.subList && (
            <div
              className="highlighted-bullet col-12"
              style={{ marginBottom: "1em" }}
            >
              <ul className="d-flex justify-content-around sub-list">
                <li>
                  Web based one click solution, no installation required for
                  patients
                </li>
                <li>Easy integration with EHR and existing infrastructure</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );

  return (
    <>
      {careFeature.map((item, index) => {
        const isEven = index % 2 === 0;
        return <div key={item.id}>{renderFeatureSection(item, isEven)}</div>;
      })}
    </>
  );
};

export default CareFeatures;
