import React from "react";

import "../css/privacyPolicy.css";
import Layout from "../components/Layout";

const meta = {
  title: "Privacy Policy",
  description:
    "This Privacy Policy covers how Kratin handles personal data, whether you interact with us on our websites (www.truliacare.com), through mobile apps published in the marketplace (such as TruliaCare clinician and TruliaCare companion), or in person (including by phone).",
  keywords:
    "Patient privacy, Data protection, Health information security, Confidentiality policy, Patient rights, Healthcare privacy, Privacy policy, Data privacy, Health data security, TruliaCare, HIPAA compliance, Medical data protection, Patient confidentiality, Information privacy, Home healthcare solutions, Hospice solutions, Healthcare compliance, Homecare, Hospice care, Home health services.",
  abstract:
    "At Kratin LLC, we help care providers deliver top-notch care using digital technology while safeguarding your data. This policy explains how we handle personal data across our website, apps, and in-person interactions.",
  canonical: "https://truliacare.com/privacy-policy",
};

const PrivacyPolicy = () => {
  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div style={{ textAlign: "center", width: "100%", paddingTop: "35px" }}>
        <div id="pricvacy-content" className="privacy-wrapper">
          <div>
            <h1>PRIVACY POLICY</h1>
            <p>Effective date: 2021-03-29</p>
            <h2>
              <strong>Introduction</strong>
            </h2>
            <p>
              At Kratin LLC, our goal is to assist the care providers in
              delivering best possible care with effective use of digital
              technology. We are committed to safeguard your data and are taking
              reasonable precautions to ensure that your information is
              accessible only to authorized persons.
            </p>
            <p>
              This document provides a clear explanation of how we collect, use,
              disclose, transfer, and store your information.
            </p>
            <p>
              This Privacy Policy covers how Kratin handles personal data
              whether you interact with us on our websites (
              <a target="_blank" href="https://truliacare.com" rel="noreferrer">
                www.truliacare.com
              </a>
              ), through mobile apps published in the marketplace (such as
              TruliaCare clinician, TruliaCare companion), or in person
              (including by phone).
            </p>
            <p>
              Kratin may also link to third parties on our services or make
              third-party solutions available as part of the ecosystem. This
              Privacy Policy does not apply to how third parties define personal
              data or how they use it. We encourage you to read their privacy
              policies before authorizing their usage or availing the services
              offered by them.
            </p>
            <p>
              Our Terms and Conditions govern all use of our Service and
              together with the Privacy Policy constitutes your agreement with
              us. By using Service, you agree to the collection and use of
              information in accordance with this policy.
            </p>
            <h2>
              <strong>Data Collected & Usage</strong>
            </h2>
            <p>
              This section lists data that we may collect based on your usage of
              offered services. This may vary depending on your informed
              consents and interactions with particular solutions. Following
              aspects of these data elements are covered in this section
            </p>
            <ol>
              <li>Data - What we collect </li>
              <li>
                Primary Purpose - Why do we need this data? Any additional
                purposes for which we may use this data is covered in the
                subsequent section titled “Secondary Usage Scenarios of Data”.
              </li>
              <li>
                Consent & Opt Out - How is user informed about collected data
                and way to opt out
              </li>
              <li>Additional information</li>
            </ol>
            <div className="numbered-sublist">
              <ol>
                <li className="section-heading">
                  Personal Data
                  <ol className="sublist-element-container" type="i">
                    <li className="sublist-heading">
                      Data
                      <p>
                        While using our Service, we may ask you to provide us
                        with certain personally identifiable information that
                        can be used to contact or identify you (
                        <strong>“Personal Data”</strong>). Personally
                        identifiable information may include, but is not limited
                        to
                      </p>
                      <ul>
                        <li>
                          Account Information: Your TruliaCare ID and related
                          account details, including email address, physical
                          Address, devices registered, account status, and age
                        </li>
                        <li>
                          Device Information. Data from which your device could
                          be identified, such as device serial number or a local
                          unique id, or about your device, such as browser type.
                        </li>
                        <li>
                          Contact Information. Data such as name, email address,
                          physical address, phone number, or other contact
                          information
                        </li>
                      </ul>
                    </li>
                    <p />
                    <li className="sublist-heading">
                      Purpose
                      <p>
                        This data is used for personalizing offered services
                        like once a user has logged in the features relevant to
                        the role are shown.{" "}
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Consent &amp; Opt out
                      <p>
                        Users provide this information by explicitly mentioning
                        this as part of Sign up or integrating via 3rd party
                        identity providers like Google auth, apple account ,
                        Enterprise authentication system like active directory
                        etc. Users can opt out by requesting a deletion of
                        account by sending email to
                        <strong>
                          <a href="mailto:accountService@truliacare.com">
                            &nbsp;<u>accountService@truliacare.com</u>
                          </a>
                        </strong>
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Additional Information
                      <p>
                        In case you have signed up as part of your organization,
                        the account is linked with the organization where you
                        are working and is delinked within 24 hours once the
                        account is deactivated/deleted by your organization.
                      </p>
                    </li>
                  </ol>
                </li>
                <li className="section-heading">
                  Location Data (Only for TruliaCare Clinician Application)
                  <ol className="sublist-element-container" type="i">
                    <li className="sublist-heading">
                      Data
                      <p>
                        Location consent is only requested for the solution
                        components that require real time location tracking to
                        optimize workflows. If you consent, the application will
                        gather your location ONLY during your shift time. There
                        is an active indicator in the notification bar during
                        this time period of location tracking. This also
                        includes change in availability of location. All data is
                        transmitted and stored securely in the system for audit
                        and generating insights.
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Purpose
                      <p>
                        This information is shared with the care coordinators of
                        your organizations to improve visit scheduling and
                        reduce the time spent in travelling. Historical location
                        data is used for audit purposes and generating insights
                        for optimizing care delivery. Location information may
                        be accessed by your employer as per your organization’s
                        policy.{" "}
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Consent &amp; Opt out
                      <p>
                        User explicitly provides the permission to the
                        application for tracking of location. You can any time
                        sign out from the shift in case you would not like your
                        location to be tracked. You can also enable or disable
                        location services when you use our Service at any time
                        by way of your device settings.
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Additional Information
                      <p>
                        Location access during your shift time may be subject to
                        your agreement with your employer and individually
                        provided consent. Retention of historical data is
                        governed by your organization policy.
                      </p>
                    </li>
                  </ol>
                </li>
                <li className="section-heading">
                  Health &amp; Fitness Data (Only for TruliaCare Companion
                  Application)
                  <ol className="sublist-element-container" type="i">
                    <li className="sublist-heading">
                      Data
                      <p>
                        Health &amp; fitness consent is only requested for the
                        solution components that are involved in patient care.
                        If you consent, the application will gather your health
                        and fitness data from 3rd party sensors, applications
                        like Apple health kit, Google fit etc. which are
                        authorized by you. This also includes any manually
                        entered data related to your health condition,
                        medications within the application.
                      </p>
                      <ul>
                        <li>
                          Health and medical data, including but not limited to
                          data from the Clinical Health Records API, HealthKit
                          API, MovementDisorderAPIs, or health-related human
                          subject research or any other user provided health or
                          medical data
                        </li>
                        <li>
                          Fitness - Fitness and exercise data, including but not
                          limited to the Motion and Fitness API
                        </li>
                      </ul>
                      <p>
                        All data is transmitted and stored securely in
                        compliance with applicable laws including, but not
                        limited to HIPAA etc.
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Purpose
                      <p>
                        This information is shared with the care provider
                        organizations for which you explicitly provide a consent
                        in the application. This information is also used to
                        facilitate better care outcomes by effective digital
                        interventions in the form of reminders, communication
                        with your care team, friends and family.
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Consent &amp; Opt out
                      <p>
                        User explicitly provide the permission inside the
                        application to the application for access to health and
                        fitness data. You can add/remove data access to any
                        organization from within the application.
                      </p>
                      <p>
                        You can obtain a copy of stored data or request a clean
                        wipe of data by sending an email to
                        <strong>
                          <a href="mailto:accountService@truliacare.com">
                            &nbsp;<u>accountService@truliacare.com.</u>
                          </a>
                        </strong>
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Additional Information
                      <p>
                        All the content is stored securely with very limited
                        access to data only for care organizations or persons
                        authorized by you. No PII or PHI is shared with external
                        organization which is not authorized for the same. Your
                        participation in any research study related to your
                        wellbeing is subject to individual consent during sign
                        up.
                      </p>
                    </li>
                  </ol>
                </li>
                <li className="section-heading">
                  Application Usage Data and Diagnostics
                  <ol className="sublist-element-container" type="i">
                    <li className="sublist-heading">
                      Data
                      <p>
                        Your interaction with the solution like app launches,
                        taps, clicks, scrolling information or other information
                        about how you are interacting with the app may be stored
                        for generating insight and improving the overall
                        experience of offered service. This also includes any
                        diagnostic data like crash logs, performance parameters
                        like launch time, any other data collected for the
                        purposes of measuring technical diagnostics that may
                        help in identifying the issue faced by you to resolve
                        it. Any sensitive information like your location, health
                        and fitness are not part of this data.{" "}
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Purpose
                      <p>
                        This information is used for product personalization,
                        analytics thereby improving the service. This may also
                        be used by customer support teams during troubleshooting
                        sessions or walkthroughs.
                      </p>
                    </li>
                    <li className="sublist-heading">
                      Consent &amp; Opt out
                      <p>
                        You can opt out of usage analytics from within the
                        application. You can obtain a copy of stored data or
                        request a clean wipe of data by sending an email to
                        <strong>
                          <a href="mailto:accountService@truliacare.com">
                            &nbsp;<u>accountService@truliacare.com.</u>
                          </a>
                        </strong>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <h2>Application Permission</h2>
            <p>
              To gather the above data, here are the permissions used by mobile
              applications (Android and iPhone)
            </p>
            <table style={{ marginBottom: "1em" }}>
              <tr>
                <th>Permission</th>
                <th style={{ width: "40%" }}>Purpose</th>
                <th style={{ width: "33%" }}>Application</th>
              </tr>
              <tr>
                <td>Ringer Volume</td>
                <td>
                  Usually care team members keep their phone silent while
                  providing care. This permission enables application to change
                  ringer volume momentarily so as to provide any care alert. The
                  ringer setting is restored after providing alert so that it
                  does interfere with user’s original setting
                </td>
                <td>TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Location permission</td>
                <td>
                  To locate clinicians so that they can be offered with patient
                  visits nearest to their location
                </td>
                <td>TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Device contact permission</td>
                <td>
                  To communicate and share information to device contacts.
                </td>
                <td>TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Overlay permission</td>
                <td>
                  To show the pending visit notifications, COVID-19 survey
                  alerts
                </td>
                <td>TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Activity recognition permission</td>
                <td>
                  To detect the activity such as walking, biking or moving in a
                  vehicle
                </td>
                <td>TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Do Not Disturb</td>
                <td>
                  DND permission to update ringer volume when phone is silent
                  for any care visit update related alerts
                </td>
                <td>TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Motion detection</td>
                <td>Researchkit uses it showing patient’s adherence</td>
                <td>TruliaCare Companion</td>
              </tr>
              <tr>
                <td>Bluetooth Peripheral</td>
                <td>
                  To integrate medical devices or wearables to continuously
                  monitor body vitals
                </td>
                <td>TruliaCare Companion</td>
              </tr>
              <tr>
                <td>Camera and media library Permission</td>
                <td>
                  This permission is needed to make a video call or to capture a
                  photo as required by the application
                </td>
                <td>TruliaCare Companion, TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Record Audio Permission</td>
                <td>
                  To use our voice bot experience via accessing microphone and
                  to record the audio during chat.
                </td>
                <td>TruliaCare Companion, TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Face ID</td>
                <td>For Unlocking application via Content Shield</td>
                <td>TruliaCare Companion, TruliaCare Clinician</td>
              </tr>
              <tr>
                <td>Storage</td>
                <td>
                  Access to local storage for saving data that can be shared
                  across
                </td>
                <td>TruliaCare Companion, TruliaCare Clinician</td>
              </tr>
            </table>
            <h2>Secondary Usage Scenarios of Data</h2>
            <p>
              Here are some secondary usage scenario for the gathered data which
              may be common for all data elements
            </p>
            <p>0.1. to provide and maintain our Service;</p>
            <p>0.2. to notify you about changes to our Service;</p>
            <p>
              0.3. to allow you to participate in interactive features of our
              Service when you choose to do so;
            </p>
            <p>0.4. to provide customer support;</p>
            <p>
              0.5. to gather analysis or valuable information so that we can
              improve our Service;
            </p>
            <p>0.6. to monitor the usage of our Service;</p>
            <p>0.7. to detect, prevent and address technical issues;</p>
            <p>0.8. to fulfil any other purpose for which you provide it;</p>
            <p>
              0.9. to carry out our obligations and enforce our rights arising
              from any contracts entered into between you and us, including for
              billing and collection;
            </p>
            <p>
              0.10. to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </p>
            <p>
              0.11. in any other way we may describe when you provide the
              information;
            </p>
            <p>0.12. for any other purpose with your consent.</p>
            <p>
              5. <strong>Retention of Data</strong>
            </p>
            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p>
              6. <strong>Transfer of Data</strong>
            </p>
            <p>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>
            <p>
              Kratin LLC will take all the steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy and no transfer of your Personal Data will take
              place to an organisation or a country unless there are adequate
              controls in place including the security of your data and other
              personal information.
            </p>
            <p>
              7. <strong>Disclosure for Law Enforcement.</strong>
            </p>
            <p>
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.
            </p>
            <p>
              8. <strong>Security of Data</strong>
            </p>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
            <p>
              9.
              <strong>
                Your Data Protection Rights Under General Data Protection
                Regulation (GDPR)
              </strong>
            </p>
            <p>
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR.
            </p>
            <p>
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.
            </p>
            <p>
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please email us
              at &nbsp;<strong>accountService@truliacare.com</strong>.
            </p>
            <p>
              In certain circumstances, you have the following data protection
              rights:
            </p>
            <p>
              0.1. The right to access, update or to delete the information we
              have on you;
            </p>
            <p>
              0.2. The right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </p>
            <p>
              0.3. The right to object. You have the right to object to our
              processing of your Personal Data;
            </p>
            <p>
              0.4. The right of restriction. You have the right to request that
              we restrict the processing of your personal information;
            </p>
            <p>
              0.5. The right to data portability. You have the right to be
              provided with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </p>
            <p>
              0.6. The right to withdraw consent. You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;
            </p>
            <p>
              Please note that we may ask you to verify your identity before
              responding to such requests. Please note, we may not be able to
              provide Service without some necessary data.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </p>
            <p>
              10.
              <strong>
                Your Data Protection Rights under the California Privacy
                Protection Act (CalOPPA)
              </strong>
            </p>
            <p>
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require a person or
              company in the United States (and conceivable the world) that
              operates websites collecting personally identifiable information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals with whom it is being shared, and to comply with
              this policy.
            </p>
            <p>According to CalOPPA we agree to the following:</p>
            <p>0.1. users can visit our site anonymously;</p>
            <p>
              0.2. our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the homepage of our website;
            </p>
            <p>
              0.3. users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </p>
            <p>
              0.4. users are able to change their personal information by
              emailing us at
              <strong>
                <a href="mailto:accountService@truliacare.com">
                  &nbsp;<u>accountService@truliacare.com</u>
                </a>
              </strong>
              .
            </p>

            <p>Our Policy on “Do Not Track” Signals:</p>
            <p>
              We honor Do Not Track signals and do not track, plant cookies, or
              use advertising when a Do Not Track browser mechanism is in place.
              Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked.
            </p>
            <p>
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.
            </p>
            <p>
              11.
              <strong>
                Your Data Protection Rights under the California Consumer
                Privacy Act (CCPA)
              </strong>
            </p>
            <p>
              If you are a California resident, you are entitled to learn what
              data we collect about you, ask to delete your data and not to sell
              (share) it. To exercise your data protection rights, you can make
              certain requests and ask us:
            </p>
            <p>
              <strong>
                0.1. What personal information we have about you. If you make
                this request, we will return to you:
              </strong>
            </p>
            <p>
              0.0.1. The categories of personal information we have collected
              about you.
            </p>
            <p>
              0.0.2. The categories of sources from which we collect your
              personal information.
            </p>
            <p>
              0.0.3. The business or commercial purpose for collecting or
              selling your personal information.
            </p>
            <p>
              0.0.4. The categories of third parties with whom we share personal
              information.
            </p>
            <p>
              0.0.5. The specific pieces of personal information we have
              collected about you.
            </p>
            <p>
              0.0.6. A list of categories of personal information that we have
              sold, along with the category of any other company we sold it to.
              If we have not sold your personal information, we will inform you
              of that fact.
            </p>
            <p>
              0.0.7. A list of categories of personal information that we have
              disclosed for a business purpose, along with the category of any
              other company we shared it with.
            </p>
            <p>
              Please note, you are entitled to ask us to provide you with this
              information up to two times in a rolling twelve-month period. When
              you make this request, the information provided may be limited to
              the personal information we collected about you in the previous 12
              months.
            </p>
            <p>
              <strong>0.2. To delete your personal information. - </strong>If
              you make this request, we will delete the personal information we
              hold about you as of the date of your request from our records and
              direct any service providers to do the same. In some cases,
              deletion may be accomplished through de-identification of the
              information. If you choose to delete your personal information,
              you may not be able to use certain functions that require your
              personal information to operate.
            </p>
            <p>
              <strong>
                0.3. To stop selling your personal information. -{" "}
              </strong>
              We don’t sell or rent your personal information to any third
              parties for any purpose. We do not sell your personal information
              for monetary consideration. However, under some circumstances, a
              transfer of personal information to a third party, or within our
              family of companies, without monetary consideration may be
              considered a “sale” under California law. You are the only owner
              of your Personal Data and can request disclosure or deletion at
              any time.
            </p>
            <p>
              If you submit a request to stop selling your personal information,
              we will stop making such transfers.
            </p>
            <p>
              Please note, if you ask us to delete or stop selling your data, it
              may impact your experience with us, and you may not be able to
              participate in certain programs or membership services which
              require the usage of your personal information to function. But in
              no circumstances, we will discriminate against you for exercising
              your rights.
            </p>
            <p>
              To exercise your California data protection rights described
              above, please send your request(s) by email:
              <strong>
                <a href="mailto:accountService@truliacare.com">
                  &nbsp;<u>accountService@truliacare.com</u>
                </a>
              </strong>
            </p>
            <p>
              Your data protection rights, described above, are covered by the
              CCPA, short for the California Consumer Privacy Act. To find out
              more, visit the official California Legislative Information
              website. The CCPA took effect on 01/01/2020.
            </p>
            <p>
              12. <strong>Children’s Privacy</strong>
            </p>
            <p>
              Our Services are not intended for use by children under the age of
              18 (<strong>“Child”</strong> or
              <strong>“Children”</strong>).
            </p>
            <p>
              We do not knowingly collect personally identifiable information
              from Children under 18. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that we have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.
            </p>
            <p>
              13. <strong>Changes to This Privacy Policy</strong>
            </p>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <p>
              14. <strong>Contact Us</strong>
            </p>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us by email:
              <strong>
                <a href="mailto:accountService@truliacare.com">
                  &nbsp;<u>accountService@truliacare.com</u>
                </a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
