import React, { useEffect, useState } from "react";
import connected from "../../assets/care-companion/connected.png";
import engage from "../../assets/care-companion/enhance-engage.png";
import careGaps from "../../assets/care-companion/care-gap.png";
import adherence from "../../assets/care-companion/adherence.png";
import dashboard from "../../assets/care-coordination/dashboard.png";
import hospiceIcon1 from "../../assets/care-coordination/04.png";
import hospiceIcon2 from "../../assets/care-coordination/05.png";

import Cards from "../../components/Cards";
import CareFeatures from "./CareFeatures";

import "./careCoordination.css";
import "../../css/cards.css";
import { scroller } from "react-scroll";
import Layout from "../../components/Layout";
import axios from "axios";

const careData = [
  {
    id: 1,
    image: connected,
    desc: "Stay Connected",
  },
  {
    id: 2,
    image: engage,
    desc: "Enhance Engagement",
  },
  {
    id: 3,
    image: careGaps,
    desc: "Identify Care Gaps",
  },
  {
    id: 4,
    image: adherence,
    desc: "Patient Adherence",
  },
  {
    id: 5,
    image: dashboard,
    desc: "Personalized Health Dashboard",
  },
];

const meta = {
  title: "Care Companion Solution for Hospice and Homecare",
  description:
    "Boost patient engagement with TruliaCare care companion solution. This personal companion helps patients follow care plans and record health data using digital sensors, with insights accessible to care professionals.",
  keywords:
    "Care companion, patient engagement solution, patient engagement software, Homecare solution, Companion Services, Virtual companion solutions, prime home health and companion care, healthcare engagement solutions, healthcare engagement solutions, health engagement platform, digital patient engagement software, HIPAA compliant ",
  abstract:
    "Care Companion is a software solution that enables caregivers and healthcare practitioners to provide individualized and efficient care. It offers capabilities for tracking patient health, organizing treatment, managing schedules, and issuing reminders, all of which aim to improve care quality and streamline tasks.",
  canonical: "https://truliacare.com/care-companion",
};

const CareCompanion = () => {
  const [careStrapiData, setCareStrapiData] = useState([]);

  const handleCardClick = (featureId) => {
    scroller.scrollTo(featureId, {
      duration: 500,
      offset: -200,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCareFeature = async () => {
      const response = await axios.get(
        "https://tbuhx03.truliacare.com/cms/api/care-companions"
      );
      const careCompanionData = response?.data?.data.map(
        (item) => item.attributes.careCompanion
      );
      const data = careCompanionData[0];
      setCareStrapiData(data);
    };
    fetchCareFeature();
  }, []);

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div className="care-coordination-overview">
        <div className="coordination-title-wrapper">
          <section>
            <div className="container care-container" style={{ width: "100%" }}>
              <h1 className="care-coordination-title">Your Care Companion</h1>
              <div className="row">
                <div
                  className="col-12"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <div>
                    <p
                      className="care-coordination-subtitle"
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      Engaging patients beyond clinical encounters with a
                      virtual care companion solution that acts as a personal
                      assistant for maintaining well-being. This assistant helps
                      patients adhere to their care plan and record their
                      current health state via digital health sensors. All
                      patient-generated data is accessible to care team members
                      in a meaningful manner.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container card-container"
              style={{ marginTop: "40px" }}
            >
              <div className="row">
                {careData.map((data) => (
                  <Cards
                    image={data.image}
                    description={data.desc}
                    index={data.id}
                    key={data.id}
                    onCardClick={handleCardClick}
                  />
                ))}
              </div>
            </div>
            {/* Innovation Images */}
            <div className="row">
              <div
                className="innovation col-6"
                onClick={() => handleCardClick(6)}
              >
                <img
                  src={hospiceIcon1}
                  alt="Innovation and engineering lab"
                  className="innovation-image"
                />
              </div>
              <div
                className="innovation col-6"
                onClick={() => handleCardClick(7)}
              >
                <img
                  src={hospiceIcon2}
                  alt="Integration with existing systems"
                  className="innovation-image"
                />
              </div>
            </div>
          </section>
          <div className="container" style={{ marginTop: "40px" }}>
            <h2 className="main-title-hospice">Features</h2>
          </div>
        </div>
        <CareFeatures careFeature={careStrapiData} />
      </div>
    </Layout>
  );
};

export default CareCompanion;
