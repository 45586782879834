import React from "react";

import "../css/contact.css";

const ContactUs = () => {
  return (
    <div className="contact-us" id="contact">
      <div className="container contact-container">
        <h2 className="contact-main-title">Get In Touch</h2>
        <div id="contactPart1">
          <div className="office-contact">
            <h3>New Jersey</h3>
            <div className="office-contact-inner">
              <p>
                <span style={{ fontSize: "1.4em" }}>Kratin LLC, </span> <br />
                131, Recklesstown Way, Chesterfield
                <br />
                New Jersey - 08515
                <br /> USA.
              </p>
            </div>

            <div className="office-contact-inner">
              <p>
                email:
                <a
                  href="mailto:info@truliacare.com"
                  rel="noreferrer"
                  className="phn-number"
                >
                  info@truliacare.com
                </a>
                <br /> call:{" "}
                <a href="tel:+1 817 898 1386" className="phn-number">
                  (+1) 817 898 1386
                </a>
                <br />
                <a
                  href="https://maps.google.co.in/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=kratin+LLC+new+jersey&amp;aq=&amp;sll=21.085962,79.081735&amp;sspn=0.281894,0.308647&amp;ie=UTF8&amp;hq=kratin+LLC&amp;hnear=New+Jersey,+United+States&amp;t=m&amp;z=11&amp;iwloc=A&amp;cid=146396354372908536&amp;ll=40.226873,-74.62997"
                  target="_blank"
                  className="contact-btn"
                  rel="noreferrer"
                >
                  View on map
                </a>
              </p>
            </div>
          </div>
          <div className="office-contact">
            <h3>Nagpur</h3>
            <div className="office-contact-inner">
              <p>
                <span style={{ fontSize: "1.4em" }}>
                  Kratin Software Solutions Pvt. Ltd.
                </span>{" "}
                <br /> B2-First Floor, 47, Harihar Nagar,
                <br /> Nagpur - 440034 <br />
                India.
              </p>
            </div>
            <div className="office-contact-inner">
              <p>
                email:
                <a href="mailto:info@truliacare.com" className="phn-number">
                  info@truliacare.com
                </a>
                <br /> call:
                <a href="tel:+1 817 898 1386" className="phn-number">
                  (+91) 7103 281291
                </a>
                <br />
                <a
                  href="https://www.google.co.in/maps/place/Kratin+Software+Solutions/@21.084315,79.084507,17z/data=!4m7!1m4!3m3!1s0x3bd4bf184bb2e3ef:0x5b81d619cf4d269a!2sKratin+Software+Solutions!3b1!3m1!1s0x3bd4bf184bb2e3ef:0x5b81d619cf4d269a?hl=en"
                  title="Kratin office in Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  className="contact-btn"
                >
                  View on map
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
