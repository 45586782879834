import React, { useEffect, useState } from "react";
import Cards from "../../components/Cards";
import dashboard from "../../assets/care-coordination/dashboard.png";
import bigpic from "../../assets/care-coordination/bigpic.png";
import communication from "../../assets/care-coordination/communication-workflow.png";
import unifying from "../../assets/care-coordination/unifying-experience.png";
import bot from "../../assets/care-coordination/intelligent-bot.png";
import secure from "../../assets/care-coordination/secure-communication.png";
import hospiceIcon1 from "../../assets/care-coordination/04.png";
import hospiceIcon2 from "../../assets/care-coordination/05.png";
import { motion } from "framer-motion";

import "./careCoordination.css";
import "../../css/cards.css";
import CareFeatures from "./CareFeatures";
import { scroller } from "react-scroll";
import Layout from "../../components/Layout";
import axios from "axios";

const careData = [
  {
    id: 1,
    image: dashboard,
    desc: "Personalized Dashboard",
  },
  {
    id: 2,
    image: secure,
    desc: "Secure Communication",
  },
  {
    id: 3,
    image: unifying,
    desc: "Unifying Experience",
  },
  {
    id: 4,
    image: bot,
    desc: "Intelligent Care Bots",
  },
  {
    id: 5,
    image: bigpic,
    desc: "Macro Process view",
  },
  {
    id: 6,
    image: communication,
    desc: "Communication Workflow",
  },
];

const leftVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const rightVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const meta = {
  title: "Care Coordination Solution for Hospice and Homecare",
  description:
    "Transform patient care with ‘TruliaCare’ Care Coordination Solutions. Our care coordination software delivers the best possible care, ensuring seamless integration with EHR, PACS, and other clinical systems for healthcare organizations that prioritize value-based care models.",
  keywords:
    "Care Coordination Solutions, Care Coordination, care coordination software, care coordination platform, patient care coordinator, value based care model, patient engagement, care management, patient management system, healthcare organizations, health care coordinator, Personalized Dashboard, , Secure Communication, Unifying Experience, intelligent assistance,, Smart Communication Workflow, Hospice care, Homecare, Post Acute care, Hospitals, Senior care facility, medical care industry, hospital health systems, patient tracking system, patient engagement solutions, outpatient care, inpatient care, coordinated health care, data driven health care.",
  abstract:
    "Efficient Care Coordination Solutions for Healthcare Providers. Enhance patient engagement and streamline care management with TruliaCare care coordination software. Integrates with EHR and PACS for a seamless, cost-effective patient care coordinator platform.",
  canonical: "https://truliacare.com/care-coordination",
};
const CareCoordination = () => {
  const [careStrapiData, setCareStrapiData] = useState([]);

  const handleCardClick = (featureId) => {
    scroller.scrollTo(featureId, {
      duration: 500,
      offset: -200,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Fetching care features
    const fetchCareFeature = async () => {
      const response = await axios.get(
        "https://tbuhx03.truliacare.com/cms/api/care-coordinations"
      );
      const careCoordinationData = response?.data?.data.map(
        (item) => item.attributes.careCorordination
      );
      const data = careCoordinationData[0];
      setCareStrapiData(data);
    };
    fetchCareFeature();
  }, []);

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div className="care-coordination-overview">
        <div className="coordination-title-wrapper">
          <section>
            <div className="container care-container" style={{ width: "100%" }}>
              <h1 className="care-coordination-title">Care Coordination</h1>
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 col-12"
                  style={{ paddingLeft: "0.5em", paddingRight: "0.4em" }}
                >
                  <div style={{ margin: "5% auto" }}>
                    <motion.p
                      className="care-coordination-subtitle"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.8 }}
                      variants={leftVariants}
                    >
                      Care coordination streamlines patient care by providing
                      the right information to the right people. Our unified
                      solution enables care teams to share information, track
                      treatments, and simplify transitions. Seamlessly
                      integrating with EHR, PACS, and other clinical systems,
                      our platform enhances care delivery and coordination at
                      every level.
                    </motion.p>
                  </div>
                </div>
                <motion.div
                  className=" col-lg-6 col-md-6 col-12 yt-link d-flex justify-content-center"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                  variants={rightVariants}
                >
                  <div className="coordination-container">
                    <div className="play">
                      <iframe
                        // width="120%"
                        height="200"
                        src="https://www.youtube.com/embed/-8miYLku2ic?si=fV7oJWxjaJTxVEVK"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
            <div
              className="container card-container"
              style={{ marginTop: "40px" }}
            >
              <div className="row">
                {careData.map((data) => (
                  <Cards
                    image={data.image}
                    description={data.desc}
                    index={data.id}
                    key={data.id}
                    onCardClick={handleCardClick}
                  />
                ))}
              </div>
            </div>
            {/* Innovation Images */}
            <div className="row">
              <div
                className="innovation col-6"
                onClick={() => handleCardClick(7)}
              >
                <img
                  src={hospiceIcon1}
                  alt="Innovation and engineering lab"
                  className="innovation-image"
                />
              </div>
              <div
                className="innovation col-6"
                onClick={() => handleCardClick(8)}
              >
                <img
                  src={hospiceIcon2}
                  alt="Integration with existing systems"
                  className="innovation-image"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
      <div
        className="container"
        style={{ marginTop: "40px", maxWidth: "985px" }}
      >
        <h2 className="main-title-hospice">Features</h2>
      </div>
      <CareFeatures careFeature={careStrapiData} />
    </Layout>
  );
};

export default CareCoordination;
