import React, { useEffect, useState } from "react";
import dicom_ingester_flow from "../../assets/discom/Dicom-01.jpg";
import dicom_dashboard from "../../assets/discom/Dicom-02.jpg";
import dicom_history_view from "../../assets/discom/Dicom-03.jpg";

import "../../css/dicom.css";
import Layout from "../../components/Layout";

const images = [
  {
    id: "1",
    img: dicom_ingester_flow,
    alt: "Dicom Imaging Result Ingester Flow",
  },
  {
    id: "2",
    img: dicom_dashboard,
    alt: "DICOM Ingester Dashboard View",
  },
  {
    id: "3",
    img: dicom_history_view,
    alt: "DICOM Ingester Version History View",
  },
];
const meta = {
  title: "DICOM Ingester Solution",
  description:
    "Discover a cost-effective DICOM ingester solution for seamless integration with any imaging source. Instantly ingest DICOM images to your PACS server without human intervention, ensuring secure and efficient imaging result management.",
  keywords:
    "Dicom, Dicom images viewer, Pacs server, Dicom in radiology, software Dicom, Dicom viewer online, Homecare solutions, Hospice Solutions, Home health solutions.",
  abstract:
    "The TruliaCare DICOM ingester solution automatically captures and securely manages DICOM images from imaging devices, integrating with PACS servers for efficient and cost-effective data handling.",
  canonical: "https://truliacare.com/dicom-ingester",
};

const Dicom = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreen, setFullScreen] = useState(null);

  const toggleFullScreen = (img) => {
    setIsFullScreen(!isFullScreen);
    setFullScreen(img);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div className="career-container">
        <div className="dicom-title-wrapper">
          <h1 className="dicom-title-header">
            TruliaCare DICOM Preview Ingester
          </h1>
          <p className="carrer-desc">
            The RIS/PACS Solution can integrate with any imaging source, like an
            ultrasound machine that provides DICOM images. The DICOM preview
            images are directly ingested as soon as the technician does the
            imaging without any human intervention. With a software-as-a-service
            business model, this solution is an extremely cost-effective way of
            managing imaging results (preview images) securely.
          </p>
          {/* <p className="carrer-desc">
          <a href="" style={{ color: "#428bca" }}>
            More Info
          </a>
        </p> */}
          <div className="row">
            {images.map((item) => (
              <div className="column" key={item.id}>
                <img
                  src={item.img}
                  alt={item.alt}
                  width="100%"
                  onClick={() => toggleFullScreen(item.img)}
                />
              </div>
            ))}
          </div>
        </div>
        {isFullScreen && (
          <div className="full-screen" onClick={toggleFullScreen}>
            <img src={fullScreen} alt="" className="full-screen-image" />
          </div>
        )}
        <hr />
        <div className="career-title-wrapper">
          <h2 className="main-carrer-title">Customer Speak</h2>
          <div className="slideShow-container">
            <div className="slides">
              <q>
                A Boston-area healthcare provider selected the DICOM Ingester
                (DI) product to improve its clinical workflow and the quality of
                captured medical images. Using the DI, clinical staff simply
                press a button on the medical imaging device, and the image is
                available within the EMR within seconds, at a highly competitive
                price. With a cloud-based EMR product, a solution that was both
                compliant with all regulatory standards and also cloud-based,
                was provided. Tightly integrated with cloud-based identity
                management products, the DI product adhered to current security
                standards (SAML), and used a lightweight connector hosted on the
                customer network to securely transmit information to the cloud
                EMR. It is estimated that operational savings in excess of 1000%
                were achieved through use of the DI product, recognized through
                reclaimed time and reduced errors.
              </q>
              <p className="author">CIO: Boston-area healthcare provider</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dicom;
