import React, { useState } from "react";
import Layout from "../components/Layout";
import post from "../assets/careers/Hiring Post-1.png";
import lifeKratin from "../assets/careers/lifeAtKratinNew.png";
import participatoryleadership from "../assets/careers/Participatory  leadership.png";
import flatHierachry from "../assets/careers/Flat hierarchy.png";
import ownership from "../assets/careers/Ownership at work.png";
import approachable from "../assets/careers/Approachable towards feedback.png";
import flexibility from "../assets/careers/Flexibility and  transparency_.png";
import { positionArray } from "../json/openings";
import "../css/careers.css";
import { Link } from "react-router-dom";

const tabs = [
  { id: "graduateContainer", label: "Freshers" },
  { id: "webContainer", label: "Frontend/Backend" },
  // { id: "serverContainer", label: "Server" },
  { id: "mobileContainer", label: "iOS/Android" },
  { id: "othersContainer", label: "Others" },
];

const meta = {
  title: "Careers",
  description:
    "Explore rewarding career opportunities at Kratin, a leading provider of innovative healthcare solutions. Join our team of professionals dedicated to advancing patient care through cutting-edge healthcare services. Apply today to make a meaningful impact.",
  keywords:
    "Kratin career, homecare, hospice care, healthcare innovation, digital health solutions, TruliaCare services, patient care technology, healthcare services, health tech lab, healthcare solutions, care provider support, medical technology, health IT, patient management, healthcare improvement, TruliaCare innovation, healthcare advancements, digital transformation in healthcare, innovative healthcare solutions, healthcare data management, clinical services.",
  abstract:
    "Explore employment opportunities at Kratin, a leader in innovative healthcare solutions. Join a team dedicated to improving patient care with cutting-edge services. Apply now to help make significant advances in healthcare.",
  canonical: "https://truliacare.com/careers",
};

const Careers = () => {
  const [activeTab, setActiveTab] = useState("graduateContainer");
  const filteredData = positionArray.filter(
    (position) => position.container === activeTab
  );
  const PositionBox = ({ data }) => {
    return (
      <div>
        {data.map((position, index) => (
          <div
            key={index}
            className={`positionBox ${index % 2 === 1 ? "altBackground" : ""}`}
          >
            <h3>{position.position}</h3>
            {position.planeText ? (
              <p>
                {position.planeText}
                &nbsp;&nbsp;
                <a
                  style={{ textDecoration: "none" }}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc1sybbylmh5jmiXXJhhysOmKTI7FXjvi8lBUbjJyXJgS2Zkg/viewform?vc=0&c=0&w=1&flr=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="knowMore">Apply Here</span>
                </a>
              </p>
            ) : (
              <>
                <p>
                  <strong style={{ fontWeight: "400" }}>Experience:</strong>{" "}
                  {position.experience || "N/A"}
                  <br />
                  {position.openings && (
                    <>
                      <strong style={{ fontWeight: "400" }}>Openings:</strong>{" "}
                      {position.openings}
                      <br />
                    </>
                  )}
                </p>
                {position.responsibilities.length > 0 && (
                  <>
                    <strong style={{ fontWeight: "400" }}>
                      Specific responsibilities Include:
                    </strong>
                    <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
                      {position.responsibilities.map((resp, index) => (
                        <li key={index}>{resp}</li>
                      ))}
                    </ul>
                  </>
                )}
                {position.skills.length > 0 && (
                  <>
                    <strong style={{ fontWeight: "400" }}>
                      Technical Skills required:
                    </strong>
                    <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
                      {position.skills.map((skill, index) => (
                        <li key={index}>{skill}</li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        ))}
        {activeTab !== "graduateContainer" && (
          <div className="positionArsenal" style={{ marginTop: "20px" }}>
            <div
              className="positiontitleWrapper"
              style={{ marginTop: "-40px" }}
            >
              <p
                className="position-subTitle"
                style={{
                  color: "#006cb5",
                  fontSize: "18px",
                  textAlign: "unset",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                }}
              >
                To apply, send your resume at &nbsp;
                <a
                  style={{ color: "#070757" }}
                  href="mailto:hire@truliacare.com"
                >
                  hire@truliacare.com
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div className="careers-title-wrapper">
        <h1 className="career-main-title">JOIN THE HUB OF EXPERTS</h1>
        <p className="career-sub-title">
          At Kratin, we believe in using technology and innovation to bring
          positive change to the lives of people. We are a team of enthusiastic
          technologists who love to code, keeping end user focus in mind, and
          create software that matters.
        </p>
        <div>
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSc1sybbylmh5jmiXXJhhysOmKTI7FXjvi8lBUbjJyXJgS2Zkg/viewform?vc=0&c=0&w=1&flr=0"
            target="_blank"
          >
            <img src={post} alt="careers drive" width="100%" />
          </Link>
        </div>
      </div>

      <div id="openings-wrapper">
        <div className="opening-title">
          <h2>Openings</h2>
        </div>
        <div className="container mt-3 career-tabs">
          <div className="">
            <div className="col-12">
              <div id="tabContainer" className="d-flex justify-content-between">
                {tabs.map((tab) => (
                  <div
                    key={tab.id}
                    className={`tab nav-link ${
                      activeTab === tab.id ? "activeTab" : ""
                    }`}
                    onClick={() => setActiveTab(tab.id)}
                    style={{ cursor: "pointer", flex: "1" }}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div id="arsenalWindow" className="mt-3">
            <PositionBox data={filteredData} />
          </div>
        </div>
      </div>

      <div id="career-overview">
        <div className="opening-title">
          <h2
            style={{
              fontSize: "2.25em",
              marginTop: "35px",
              marginBottom: "10px",
            }}
          >
            Come and join us, If you have...
          </h2>
          <ul
            style={{
              listStyle: "none",
              marginLeft: "-20px",
              marginTop: "30px",
            }}
          >
            <li>
              <strong style={{ fontWeight: "500" }}>PASSION</strong> for
              cutting-edge technologies & innovation
            </li>
            <li>
              {" "}
              <strong style={{ fontWeight: "500" }}>ZEAL</strong> to build
              solutions for the real life challenges
            </li>
            <li>
              <strong style={{ fontWeight: "500" }}>GUTS</strong> to stand up
              for quality work
            </li>
            <li>
              <strong style={{ fontWeight: "500" }}>DESIRE</strong> to be part
              of one of the best teams in the world
            </li>
            <li>
              <strong style={{ fontWeight: "500" }}>BELIEF</strong> in Values
              and not in Rules
            </li>
            <li>
              and <strong style={{ fontWeight: "500" }}>AIM</strong> to bring
              positive change in people life.
            </li>
          </ul>
        </div>
      </div>

      <div className="row lifeAtKratinSection">
        <div className="col-2 lifestyle-wrap ">
          <div>
            <img className="lifeAtKratin" src={lifeKratin} alt="" />
            <div className="lifeAtStyle" style={{ textWrap: "nowrap" }}>
              Life at <br />
              <span style={{ color: "#b7d12f" }}>Kr</span>atin
            </div>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <div className="footerContent">
            <img className="footerIcons" src={participatoryleadership} alt="" />
            <p className="footerIconDesc">
              Participatory <br />
              leadership
            </p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <div className="footerContent">
            <img
              className="footerIcons"
              style={{ marginTop: 5 }}
              src={flatHierachry}
              alt=""
            />
            <p className="footerIconDesc" style={{ marginTop: 5 }}>
              Flat <br />
              hierarchy
            </p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <div className="footerContent">
            <img
              className="footerIcons ownershipIconAdj"
              style={{ width: "20%" }}
              src={ownership}
              alt=""
            />
            <p className="footerIconDesc">
              Ownership <br />
              at work
            </p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <div className="footerContent">
            <img
              className="footerIcons approcchIconAdj"
              src={approachable}
              alt=""
            />
            <p className="footerIconDesc">
              Approachable towards
              <br />
              feedback
            </p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <div className="footerContent">
            <img
              className="footerIcons flexibilityIconAdj"
              src={flexibility}
              alt=""
            />
            <p className="footerIconDesc">
              Flexibility and <br />
              transparency
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Careers;
