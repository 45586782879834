import React, { useEffect, useState } from "react";
import intelligentBot from "../../assets/care-coordination/intelligent-bot.png";
import machineLearning from "../../assets/care-coordination/Machine-Learning.png";
import dashboard from "../../assets/care-coordination/dashboard.png";
import integrated from "../../assets/care-coordination/Integrated-Systems.png";
import unifing from "../../assets/care-coordination/unifying-experience.png";
import iot from "../../assets/care-coordination/iot3.png";

import { motion } from "framer-motion";

import CareFeatures from "../Solution/CareFeatures";

import "../Solution/careCoordination.css";
import "../../css/cards.css";
import Cards from "../../components/Cards";
import { scroller } from "react-scroll";
import Layout from "../../components/Layout";
import axios from "axios";

const careData = [
  {
    id: "1",
    image: intelligentBot,
    desc: "Intelligent BOTs",
  },
  {
    id: "2",
    image: machineLearning,
    desc: "Machine Learning",
  },
  {
    id: "3",
    image: dashboard,
    desc: "Digital Insights",
  },
  {
    id: "4",
    image: integrated,
    desc: "Integrated Systems",
  },
  {
    id: "5",
    image: unifing,
    desc: "Ubiquitous Experience",
  },
  {
    id: "6",
    image: iot,
    desc: "Internet Of \nThings",
  },
];

const leftVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
    },
  },
};

const meta = {
  title: "Innovation and Technology Lab",
  description:
    "Explore Truliacare's Innovation & Engineering Lab, where cutting-edge healthcare technologies are researched, prototyped, and deployed to deliver seamless and integrated health experiences.",
  keywords:
    "Homecare, hospice care, healthcare innovation, digital health solutions, TruliaCare services, patient care technology, healthcare services, health tech lab, healthcare solutions, care provider support, medical technology, health IT, patient management, healthcare improvement, TruliaCare innovation, healthcare advancements, digital transformation in healthcare, innovative healthcare solutions, healthcare data management, clinical services.",
  abstract:
    "Truliacare’s Innovation & Engineering Lab pioneers in AI-driven diagnostics and telehealth solutions, enhancing patient care and operational efficiency for a unified healthcare experience.",
  canonical: "https://truliacare.com/innovation-lab",
};
const InnovationPage = () => {
  const [innovData, setInnovData] = useState([]);

  const handleCardClick = (featureId) => {
    scroller.scrollTo(featureId, {
      duration: 500,
      offset: -200,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCareFeature = async () => {
      const response = await axios.get(
        "https://tbuhx03.truliacare.com/cms/api/innovation-labs"
      );
      const innovData = response?.data?.data.map(
        (item) => item.attributes.innovation
      );
      const innovationData = innovData[0];
      setInnovData(innovationData);
    };
    fetchCareFeature();
  }, []);
  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div className="care-coordination-overview">
        <div className="coordination-title-wrapper">
          <section>
            <div className="container care-container" style={{ width: "100%" }}>
              <h1
                className="care-coordination-title"
                style={{ fontSize: "2.5em" }}
              >
                Technology &amp; Innovation is our Forte
              </h1>
              <div className="row">
                <div
                  className="col-12"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <div>
                    {/* add animation */}
                    <motion.p
                      className="care-coordination-subtitle"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.8 }}
                      variants={leftVariants}
                    >
                      In keeping with our mission to revolutionize healthcare
                      through advanced technology, we continuously seek to
                      expand TruliaCare's solution portfolio to meet the
                      evolving needs of our customers. Our Innovation &
                      Engineering Lab focuses on researching and prototyping
                      cutting-edge technologies, such as AI-driven diagnostics
                      and telehealth platforms, to create seamless and
                      integrated healthcare experiences. By deploying these
                      next-generation solutions, we aim to enhance patient
                      outcomes, streamline operations for healthcare providers,
                      and ensure a cohesive, efficient health journey for all
                      stakeholders.
                    </motion.p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container card-container"
              style={{ marginTop: "40px" }}
            >
              <div className="row">
                {careData.map((data) => (
                  <Cards
                    image={data.image}
                    description={data.desc}
                    index={data.id}
                    key={data.id}
                    onCardClick={handleCardClick}
                  />
                ))}
              </div>
            </div>
          </section>
          <div className="container" style={{ marginTop: "40px" }}>
            <h2 className="main-title-hospice">Features</h2>
          </div>
        </div>
        <CareFeatures careFeature={innovData} />
      </div>
    </Layout>
  );
};

export default InnovationPage;
