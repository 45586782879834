export const positionArray = [
  {
    position: "Freshers Hiring",
    experience: "",
    openings: "",
    responsibilities: [""],
    planeText:
      "We strongly believe that every individual has the potential to shine under the right mentors. We love to work with highly energetic, sincere, and smart freshmen who are ready to LEARN and GROW. Working with Kratin straight from college is an amazing experience, and it's definitely not like any average entry-level program. You won't have months of teaching and shadowing. Our program is an intensive, hands-on experience, learning how to build software and discovering the values, practices, and principles that we believe in. You will be working with real life clients within the first few months, and by the end of the program, you will have real-life experience under your belt.",
    container: "graduateContainer",
  },

  {
    position: "Angular Developer",
    experience: "3+ Years.",
    openings: "01",
    responsibilities: [],
    skills: [
      "Should have more than 1+ years of experience in Angular.",
      "Must have experience with Angular version 5+",
      "Strong knowledge of Object Oriented Programming concepts in javascript.",
      "Knowledge of life cycle hooks.",
      "Should have knowledge of adding javascript library in angular.",
      "Must have knowledge of prototype, typescript.",
      "Understands writing quality code - naming conventions, exception handling, reuse.",
      "Good knowledge of HTML, CSS, javascript.",
      "Understanding of Agile methodologies.",
      "Highly developed analytical and problem-solving skills.",
      "Excellent troubleshooting and communication skills.",
    ],
    container: "webContainer",
  },
  {
    position: "PHP Developer",
    experience: "2+ Years.",
    openings: "",
    responsibilities: [
      "Develop, record and maintain cutting edge web-based PHP applications on portal plus premium service platforms",
      "Build innovative, state-of-the-art applications and collaborate with the User Experience (UX) team",
      "Ensure HTML, CSS, and shared JavaScript is valid and consistent across applications",
      "Prepare and maintain all applications utilizing standard development tools and Utilize backend data services and contribute to increase existing data services API",
      "Lead the entire web application development life cycle right from concept stage to delivery and post launch support",
      "Convey effectively with all task progress, evaluations, suggestions, schedules along with technical and process issues",
      "Document the development process, architecture, and standard components",
      "Coordinate with co-developers and keeps project manager well informed of the status of development effort and serves as liaison between development staff and project manager",
    ],
    skills: [
      "Should have good knowledge on Html 5, CSS3, JQuery, Bootstrap",
      "Good to have knowledge of View JS, Firestore",
      "Excellent communication skills.",
      "Attention to detail.",
      "Analytical mind and problem-solving aptitude.",
      "Strong organizational skills.",
    ],
    container: "webContainer",
  },
  {
    position: "Dotnet Developer",
    experience: "4+ Years.",
    openings: "02",
    responsibilities: [
      "To develop, design, test, maintain and support custom mobile and web applications.",
      "To develop documentation throughout the software development life cycle (SDLC).",
      "Upgrading, configuring, and debugging existing systems with maintaining operational efficiency.",
      "Evaluate and research the products and technologies related to software.",
      "Alienate the project work and write high-quality, clean, and scalable code to meet the client's requirements.",
      "Collaborate with other team members and stakeholders.",
    ],
    skills: [
      "Database Design - Relations, Cardinality, Constraints, Indexes.",
      "Database Data Manipulation - Select, Joins, Group By, Aggregate Functions, Subquery, Stored Procedures, Functions.",
      "ASP.Net MVC, SQL, Entity Framework, LINQ, JQuery,and Dependency Injection.",
      "Writing Quality Code - Naming Conventions, Exception Handling, Logging, Reusability, OOP Concepts, SOLID principles.",
      "Understanding of Agile methodology.",
      "Highly developed analytical and problem-solving skills.",
      "Excellent troubleshooting and communication skills.",
    ],
    container: "webContainer",
  },
  {
    position: "Android Developer",
    experience: "2+ Years.",
    openings: "",
    responsibilities: [
      "We are looking for an Android developer responsible for the development and maintenance of applications aimed at a vast number of diverse Android devices.",
      "Your primary focus will be the development of Android applications and their integration with back-end services.",
      "Identify and correct bottlenecks and fix bugs.",
      "Help maintain code quality, organization, and automatization",
      "Design, build and maintain high performance, reusable, and reliable Java code.",
      "Translate designs and wireframes into high-quality code",
    ],
    skills: [
      "Strong knowledge of Kotlin,Android SDK, different versions of Android, and how to deal with different screen sizes",
      "Familiarity with Android Jetpack components,RESTful APIs to connect Android applications to back-end services",
      "Strong knowledge of Android UI design principles, patterns, and best practices",
      "Experience with offline storage, threading, and performance tuning",
      "Ability to design applications around natural user interfaces, such as “touch”",
      "Familiarity with the use of additional sensors, such as gyroscopes and accelerometers",
      "Knowledge of the open-source Android ecosystem and the libraries available for common tasks",
      "Ability to understand business requirements and translate them into technical requirements",
      "Familiarity with cloud message APIs and push notifications",
      "A knack for benchmarking and optimization",
      "Understanding of Google’s Android design principles and interface guidelines",
      "Proficient understanding of code versioning tools, such as Git",
      "Familiarity with continuous integration",
      "Strong communication and interpersonal skills",
      "Knowledge of Android SDK, RESTful APIs,Android Jetpack components,Kotlin, Android UI design",
    ],
    container: "mobileContainer",
  },
  {
    position: "IOS Developer",
    experience: "2+ Years.",
    openings: "",
    responsibilities: [
      "Designing and building mobile applications for Apple’s iOS platform.",
      "Collaborating with the design team to define app features.",
      "Ensuring the quality and performance of the application to specifications.",
      "Identifying potential problems and resolving application bottlenecks.",
      "Fixing application bugs before the final release.",
      "Publishing application on App Store.",
      "Maintaining the code and atomization of the application.",
      "Designing and implementing application updates.",
    ],
    skills: [
      "Proven experience as an app developer.",
      "Proficient in Objective-C, Swift, and Cocoa Touch.",
      "Extensive experience with iOS Frameworks such as Core Data and Core Animation.",
      "Knowledge of iOS back-end services.",
      "Knowledge of other web technologies and UI/UX standards",
      "Knowledge of Apple’s design principals and application interface guidelines.",
      "Proficient in code versioning tools including Mercurial, Git, and SVN..",
      "Knowledge of C-based libraries..",
      "Familiarity with push notifications, APIs, and cloud messaging.",
      "Experience with continuous integration.",
    ],
    container: "mobileContainer",
  },
  {
    position: "Recruitment Coordinator",
    experience: "Should have 2+ yr. Of experience in recruitment",
    openings: "01",
    responsibilities: [
      "Proven work experience as an IT Recruitment",
      "Familiarity with Applicant Tracking Systems and resume databases",
      "Experience with sourcing techniques",
      "Understanding of referral programs",
      "Solid verbal and written communication skills",
      "Assist with all internal and external HR-related matters.",
      "Participate in developing organizational guidelines and procedures.",
      "Recommend strategies to motivate employees.",
      "Assist with the recruitment process by identifying candidates, conducting reference checks, and issuing employment contracts.",
      "Investigate complaints brought forward by employees.",
      "Coordinate employee development plans and performance management.",
      "Perform orientations and update records of new staff.",
      "Manage the organization’s employee database and prepare reports.",
      "Produce and submit reports on general HR activity.",
      "Assist with budget monitoring and payroll.",
      "Keep up-to-date with the latest HR trends and best practices.",
    ],
    skills: ["Good communication skills"],
    container: "othersContainer",
  },
  {
    position: "Testing QA",
    experience: "2+ Years.",
    openings: "",
    responsibilities: [],
    skills: [
      "Knowledge on SDLC and STLC model.",
      "Test Scenario/Test Case writing techniques.",
      "Proven experience as a Quality Assurance Tester or similar role.",
      "Experience in project management and QA methodology.",
      "Familiarity with Agile frameworks.",
      "Ability to document and troubleshoot errors.",
      "Working knowledge of test management software (e.g.JIRA,TFS,Redmine).",
      "Excellent communication skills.",
      "Attention to detail.",
      "Analytical mind and problem-solving aptitude.",
      "Strong organizational skills.",
      "Great interpersonal and communication skills.",
    ],
    container: "othersContainer",
  },

  {
    position: "Scrum Master",
    experience: "3+ Years.",
    openings: "",
    responsibilities: [
      "Using agile methodology values, principles, and practices to plan, manage, and deliver solutions.",
      "Training, mentoring, and supporting scrum teams to follow agile values, principles, and practices.",
      "Determining and managing tasks, issues, risks, and action items.",
      "Coordinate sprints, retrospective meetings and daily stand-ups",
      "Scheduling and facilitating scrum events, meetings, and decision-making processes.",
      "Monitoring progress and performance and helping teams to make improvements.",
      "Planning and organizing demos and product/system testing.",
      "Ensuring the proper use of collaborative processes and removing impediments for the scrum team.",
      "Tracking project processes, deliverables, and financials.",
      "Preparing and presenting status reports to stakeholders.",
      "Work with product owners to handle backlogs and new requests",
      "Resolve conflicts and remove obstacles that occur",
      "Help teams implement changes effectively",
      "Ensure deliverables are up to quality standards at the end of each sprint",
      "Guide development teams to higher scrum maturity",
      'Help build a productive environment where team members "own" the product and enjoy working on it.',
    ],
    skills: [
      "Experience in a scrum master role.",
      "Familiarity with software development.",
      "Excellent knowledge of Scrum techniques and artifacts (such as definition of done, user stories, automated testing, backlog refinement)",
      "Problem-solving and conflict-resolution ability.",
      "Scrum master certification.",
    ],
    container: "othersContainer",
  },
];
